import React from 'react';
import LayoutES from '../components/layout.es';
import Seo from '../components/seo.es';

export default function Privacidad() {
	return (
		<LayoutES>
			<Seo title='Política de cookies' />
			<main>
				<div
					style={{
						margin: '0 auto',
						width: '80%',
						maxWidth: '800px',
						padding: '60px 0',
					}}
				>
					<h1 style={{ marginTop: '3rem' }}>Cookies policy</h1>
					<p>
						WEB: <a href='https://www.kuflow.com'>https://www.kuflow.com</a>
					</p>
					<h2>1. What are cookies?</h2>
					<p>
						Cookies are files that can be downloaded to your computer through
						web pages. They are tools that play an essential role in the
						provision of numerous services of the information society. Among
						others, they allow a web page to store and retrieve information
						about the browsing habits of a user or their equipment and,
						depending on the information obtained, they can be used to recognize
						the user and improve the service offered.
					</p>
					<h2>2. Types of cookies</h2>
					<p>
						Depending on who is the entity that manages the domain from where
						the cookies are sent and treats the data obtained, two types can be
						distinguished:
					</p>
					<ul>
						<li>
							Own cookies: those that are sent to the user's terminal equipment
							from a computer or domain managed by the editor itself and from
							which the service requested by the user is provided.
						</li>
						<li>
							Third-party cookies: those that are sent to the user's terminal
							equipment from a computer or domain that is not managed by the
							publisher, but by another entity that processes the data obtained
							through cookies.
						</li>
					</ul>
					<p>
						In the event that cookies are installed from a computer or domain
						managed by the publisher himself, but the information collected
						through them is managed by a third party, they cannot be considered
						as own cookies.
					</p>
					<p>
						There is also a second classification according to the period of
						time that they remain stored in the client's browser, which may be:
					</p>
					<ul>
						<li>
							Session cookies: designed to collect and store data while the user
							accesses a web page. They are usually used to store information
							that is only of interest for the provision of the service
							requested by the user on that single occasion.
						</li>
						<li>
							Persistent cookies: the data is still stored in the terminal and
							can be accessed and processed for a period defined by the person
							responsible for the cookie, which can range from a few minutes to
							several years.
						</li>
					</ul>
					<p>
						Finally, there is another classification with five types of cookies
						according to the purpose for which the data obtained is processed:
					</p>
					<ul>
						<li>
							Technical cookies: those that allow the user to navigate through a
							web page, platform or application and use the different options or
							services that exist in it, such as, for example, controlling
							traffic and data communication, identifying the session, access
							parts of restricted access, remember the elements that make up an
							order, carry out the process of purchasing an order, make a
							request for registration or participation in an event, use
							security elements while browsing, store content for dissemination
							of videos or sound or share content through social networks.
						</li>
						<li>
							Personalization cookies: allow the user to access the service with
							some predefined general characteristics based on a series of
							criteria in the user's terminal, such as the language, the type of
							browser through which to access the service, the regional
							configuration from where to access the service, etc.
						</li>
						<li>
							Analysis cookies: they allow the person responsible for them to
							monitor and analyze the behavior of the users of the websites to
							which they are linked. The information collected through this type
							of cookies is used to measure the activity of the websites,
							application or platform and to create navigation profiles of the
							users of these sites, applications and platforms, in order to
							introduce improvements based on the analysis of the usage data
							made by the users of the service.
						</li>
						<li>
							Advertising cookies: allow the management, in the most efficient
							way possible, of the advertising spaces.
						</li>
						<li>
							Behavioral advertising cookies: they store information on the
							behavior of users obtained through the continuous observation of
							their browsing habits, which allows the development of a specific
							profile to display based on such behavior.
						</li>
						<li>
							Cookies from external social networks: they are used so that
							visitors can interact with the content of different social
							platforms (facebook, youtube, twitter, linkedIn, etc.) and that
							are generated only for users of said social networks. The
							conditions of use of these cookies and the information collected
							are regulated by the privacy policy of the corresponding social
							platform.
						</li>
					</ul>
					<h2>3. Third-party cookies used</h2>
					<table>
						<tr>
							<th>Cookie</th>
							<th>Persist for</th>
							<th>Type</th>
							<th>Description</th>
						</tr>
						<tr>
							<td>gdpr_google_tagmanager</td>
							<td>2 years</td>
							<td>Cookies acceptance</td>
							<td>
								Used to confirm that the user has given permission to collect
								analytics data.
							</td>
						</tr>
						<tr>
							<td>_ga</td>
							<td>2 years</td>
							<td>Analysis</td>
							<td>Used to distinguish users.</td>
						</tr>
						<tr>
							<td>_gid</td>
							<td>24 hours</td>
							<td>Analysis</td>
							<td>Used to distinguish users.</td>
						</tr>
						<tr>
							<td>{`_ga_<container-id>`}</td>
							<td>2 years</td>
							<td>Analysis</td>
							<td>Used to persist session state.</td>
						</tr>
						<tr>
							<td>{`_gac_gb_<container-id>`}</td>
							<td>90 days</td>
							<td>Analysis</td>
							<td>
								Contains campaign related information. If you have linked your
								Google Analytics and Google Ads accounts, Google Ads website
								conversion tags will read this cookie unless you opt-out.
							</td>
						</tr>
						<tr>
							<td>YSC</td>
							<td>Session</td>
							<td>Preferences</td>
							<td>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</td>
						</tr>
						<tr>
							<td>yt-remote-device-id</td>
							<td>Persistent</td>
							<td>Preferences</td>
							<td>Stores the user's video player preferences using embedded YouTube videos.</td>
						</tr>
						<tr>
							<td>yt-remote-connected-devices</td>
							<td>Persistent</td>
							<td>Preferences</td>
							<td>Stores the user's video player preferences using embedded YouTube videos.</td>
						</tr>
						<tr>
							<td>yt-remote-session-app</td>
							<td>Session</td>
							<td>Preferences</td>
							<td>Stores the user's video player preferences using embedded YouTube videos.</td>
						</tr>
						<tr>
							<td>yt-remote-cast-installed</td>
							<td>Session</td>
							<td>Preferences</td>
							<td>Stores the user's video player preferences using embedded YouTube videos.</td>
						</tr>
						<tr>
							<td>yt-remote-session-name</td>
							<td>Session</td>
							<td>Preferences</td>
							<td>Stores the user's video player preferences using embedded YouTube videos.</td>
						</tr>
					</table>
					<h2>4. Deactivation and elimination of cookies</h2>
					<p>
						You have the option to allow, block or delete the cookies installed
						on your computer by configuring the browser options installed on
						your computer. By deactivating cookies, some of the available
						services may no longer be operational. The way to disable cookies is
						different for each browser, but it can usually be done from the
						Tools or Options menu. You can also consult the Help menu of the
						browser where you can find instructions. The user may at any time
						choose which cookies he wants to work on this website.
					</p>
					<p>
						You can allow, block or delete the cookies installed on your
						computer by configuring the browser options installed on your
						computer:
					</p>
					<ul>
						<li>
							Microsoft Internet Explorer / Microsoft Edge:
							<a href='https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d'>
								https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
							</a>
						</li>
						<li>
							Mozilla Firefox
							<a href='https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox'>
								https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox
							</a>
						</li>
						<li>
							Chrome
							<a href='https://support.google.com/accounts/answer/61416'>
								https://support.google.com/accounts/answer/61416
							</a>
						</li>
						<li>
							Safari
							<a href='http://support.apple.com/kb/HT1677'>
								http://support.apple.com/kb/HT1677
							</a>
						</li>
					</ul>
				</div>
			</main>
		</LayoutES>
	);
}
